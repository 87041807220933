<template>
    <div class="container err404" :class='$mq'>
        <h2>404 <span>ошибка</span></h2>
        <div class="er-text" :class='$mq'>
            <p>Дорогая, ты случайно перешла на страничку, которой больше не существует.</p>
            <p>Лучше возвращайся обратно, там намного интереснее! :)</p>
            <button @click="back">Вернуться назад</button>
        </div>
     </div>
</template>


<script>
    export default {
        methods: {
            back (){
                this.$router.go(-2)
            }
        }
    }
</script>

<style>
    .err404 {
        display: flex;
        max-width: 900px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin: 100pt auto;
    }

        .err404.halftablet, .err404.mobile {
            flex-wrap: wrap;
            text-align: left;
            margin: 4em auto 0 auto;
            justify-content: center;
        }

    .err404 h2 {
        font-size: 15em;
        position: relative;
        line-height: 170pt;
        margin: 0;
    }
        .err404.halftablet h2, .err404.mobile h2 {
            font-size: 100pt;
            line-height: 100pt;
        }

    .err404 span {
        font-family: 'TheArtist', sans-serif;
        color: #5F3D3E;
        font-size: 0.4em;
        top: 100px;
        right: 0px;
        position: absolute;
    }
        .err404.halftablet span, .err404.mobile span {
            font-size: 0.4em;
            top: 60px;
            right: s0px;
        }

    .err404 p {
        margin-bottom: 20px;
        line-height: 18pt;
    }

    .err404 button {
        color:white;
        padding: 15px 40px;
        border: none;
        background-color: #EF8D7F;
        transition: all 0.3s cubic-bezier(.54,0,.4,1.51);
    }

        .err404 button:hover {
            transform: scale(1.1);
        }

    .er-text {
        width: 40%;        
    }

        .er-text.halftablet, .er-text.mobile  {
            width: 50%;
            font-size: 11pt;
            margin-top: 20px;
            margin-left: 10%;
        }

         .er-text.mobile {
             width: 80%;
         }
</style>